import React from 'react';
import ReactDOM from 'react-dom';
import './services/localization/i18n';


/**
 * Dynamically imports the correct applicationentrypoint based on the env. flag.
 *
 * "REACT_APP_BUILD_TARGET = standalone-search" targets the standalone search component while
 *
 * In order to overcome the limitations of Create React App without ejecting the entire configuration
 * it's more convenient to use Webpacks treeshaking functionality to prune any unwanted code from the
 * final output file and have two separate build configurations, one for the standalone-search box and one for the main
 * application. If possible try to avoid ejecting CRA in favour of project simplicity.
 */
async function importEntryPoint(): Promise<() => JSX.Element> {
  if (process.env.REACT_APP_BUILD_TARGET === 'standalone-search') {
    return (await await import('./standalone-search')).StandaloneSearch;
  } else if (process.env.REACT_APP_BUILD_TARGET === 'mdt-app' || process.env.REACT_APP_BUILD_TARGET === undefined) {
    return (await import('./mdt-app')).MdtApp;
  }

  return await Promise.reject(new Error(`Unknown build target: ${process.env.REACT_APP_BUILD_TARGET ?? 'undefined'}`));
}

void importEntryPoint().then((App) => {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root'),
  );
});
