import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector, { DetectorOptions } from 'i18next-browser-languagedetector';
import eng from './translation-english.json';
import swe from './translation-swedish.json';

export const languages = ['en', 'sv'];

export type LanguageTypeMap = typeof eng & typeof swe;

declare module 'react-i18next' {
  interface CustomTypeOptions {
    defaultNS: 'eng';
    resources: {
      eng: LanguageTypeMap;
      swe: LanguageTypeMap;
    };
  }
}

const usePath = process.env.REACT_APP_BUILD_TARGET === 'standalone-search';

const detection: DetectorOptions = {
  order: ['querystring', usePath ? 'path' : 'localStorage'],
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    detection,
    fallbackLng: 'sv',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: eng,
      },
      sv: {
        translation: swe,
      },
    },
  });

export default i18n;
